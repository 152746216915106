*,
*::before,
*::after {
  box-sizing: inherit;
}

@font-face {
  font-family: 'ProxmiaNova';
  font-style: normal;
  font-weight: 400;
  src: url('./Proxima-Nova-Regular.ttf');
}

@font-face {
  font-family: 'ProxmiaNovaLight';
  font-style: normal;
  font-weight: 400;
  src: url('./Proxima-Nova-Light.ttf');
}

@font-face {
  font-family: 'ProxmiaNovaBold';
  font-style: normal;
  font-weight: 400;
  src: url('./Proxima-Nova-Bold.ttf');
}

html {
  overflow-y: scroll;
  box-sizing: border-box;
  font-family: 'ProxmiaNova';
  color: #6e6e6e;
  -webkit-font-smoothing: antialiased;
}

input, select {
  font-size: 16px;
  line-height: 20px;
  color: #6e6e6e;
}

button {
  font-size: 18px;
  line-height: 20px;
  user-select: none;
}

label {
  font-size: 16px;
  display: block;
  color: #93a2b1;
  font-weight: 600;
}

body,
h1 {
  margin: 0;
  min-width: 1050px;
  background-color: #fff;
}

/* disabe Chrome yellow field */
input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset;
  -webkit-box-shadow: 0 0 0 30px white inset;
  -webkit-text-fill-color: #6e6e6e !important;
}

/* react-modal */
.ReactModal__Content {
  transform: translateX(717px);
  transition: all 250ms ease;
}

.ReactModal__Content--after-open {
  transform: translateX(0);
}

.ReactModal__Content--before-close {
  transform: translateX(717px);
}

.ReactModal__Body--open {
  overflow-y: hidden;
  overflow-x: hidden;
}

/* react tooltip */
.ra-tooltip {
  border-radius: 5px;
  border: 1px solid #e1e7ed;
  background-color: #e1e7ed;
  font-size: 12px;
  font-weight: 100;
  z-index: 1;
}

.ra-tooltip-message {
  padding: 8px 20px 6px;
}

.ra-tooltip-message p, .ra-tooltip-message div, .ra-tooltip-message a {
  color: #1d2c4c !important;
}

.ra-tooltip-message:after {
  margin-top: -10px !important;
  border-width: 10px !important;
}

a.description {
  color:#6e6e6e;
}

.ace_text-input {
  position: absolute!important;
}
